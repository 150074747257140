// Generated by npm run gen:icons

export const IconArrowBlockUp = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowBlockUp"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M13.8,20h-3.6c-.3,0-.5,0-.7-.1-.2,0-.3-.3-.4-.4-.1-.2-.1-.4-.1-.7v-7.8h-2.7c-.2,0-.5-.1-.6-.4,0-.2,0-.5.1-.7l5.7-5.7c.2-.2.6-.2.9,0l5.7,5.7c.2.2.2.4.1.7s-.3.4-.6.4h-2.7v7.8c0,.3,0,.5-.1.7-.1.2-.3.3-.4.4-.2.1-.4.1-.7.1ZM7.7,9.7h1.8c.3,0,.6.3.6.6v8.4h0s0,0,0,0h3.7v-8.4c0-.3.3-.6.6-.6h1.8l-4.3-4.3-4.3,4.3Z" />
  </svg>
);

export const IconArrowLeft = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowLeft"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M11.72 4.045c-.076.023-.22.102-.32.176-.099.074-1.766 1.724-3.704 3.666-2.402 2.409-3.547 3.584-3.599 3.693-.1.215-.101.625 0 .84.105.224 7.259 7.378 7.483 7.483.211.099.627.101.824.004.19-.093.382-.284.488-.485.112-.213.117-.615.01-.842-.05-.108-.956-1.043-2.777-2.869l-2.702-2.71 5.918-.01c5.603-.01 5.927-.015 6.063-.083.191-.095.382-.286.488-.486.11-.21.117-.617.014-.828a1.268 1.268 0 0 0-.5-.501c-.139-.069-.438-.074-6.065-.084l-5.919-.01 2.685-2.69c1.853-1.855 2.713-2.745 2.777-2.869.118-.229.128-.629.022-.846a1.242 1.242 0 0 0-.493-.495 1.192 1.192 0 0 0-.693-.054" />
  </svg>
);

export const IconArrowNarrowDown = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowNarrowDown"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 16 16"
    role="img"
  >
    <path d="M7.902 2.187 C 7.759 2.219,7.643 2.304,7.573 2.428 L 7.507 2.547 7.500 7.333 L 7.493 12.119 5.900 10.529 C 5.024 9.654,4.266 8.918,4.215 8.893 C 3.995 8.782,3.696 8.874,3.577 9.089 C 3.501 9.228,3.493 9.417,3.560 9.549 C 3.620 9.669,7.607 13.664,7.754 13.752 C 7.910 13.844,8.090 13.844,8.246 13.752 C 8.393 13.664,12.380 9.669,12.440 9.549 C 12.647 9.137,12.183 8.688,11.772 8.902 C 11.709 8.934,11.026 9.598,10.093 10.532 L 8.520 12.107 8.507 7.327 L 8.493 2.547 8.423 2.426 C 8.315 2.244,8.096 2.143,7.902 2.187" />
  </svg>
);

export const IconArrowNarrowRight = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowNarrowRight"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M14,19c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l4.3-4.3H4c-.6,0-1-.4-1-1s.4-1,1-1h13.6l-4.3-4.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l6,6c0,0,.2.2.2.3,0,.1,0,.2,0,.4h0c0,.1,0,.3,0,.4,0,.1-.1.2-.2.3l-6,6c-.2.2-.5.3-.7.3Z" />
  </svg>
);

export const IconArrowNarrowUp = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowNarrowUp"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 16 16"
    role="img"
  >
    <path d="M7.907 2.186 C 7.863 2.196,7.791 2.224,7.747 2.247 C 7.631 2.309,3.614 6.342,3.559 6.451 C 3.358 6.855,3.813 7.309,4.215 7.107 C 4.266 7.082,5.024 6.346,5.900 5.471 L 7.493 3.881 7.500 8.667 L 7.507 13.453 7.577 13.574 C 7.771 13.903,8.237 13.900,8.427 13.569 L 8.493 13.453 8.500 8.667 L 8.507 3.881 10.100 5.471 C 10.976 6.346,11.734 7.082,11.785 7.107 C 12.188 7.309,12.642 6.854,12.440 6.451 C 12.381 6.332,8.393 2.336,8.253 2.255 C 8.128 2.182,8.018 2.160,7.907 2.186" />
  </svg>
);

export const IconAsterisk = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconAsterisk"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M12,21c-.6,0-1-.4-1-1v-5.6l-4.3,4.3c-.4.4-1,.4-1.4,0s-.4-1,0-1.4l4.3-4.3h-5.6c-.6,0-1-.4-1-1s.4-1,1-1h5.6l-4.3-4.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l4.3,4.3v-5.6c0-.6.4-1,1-1s1,.4,1,1v5.6l4.3-4.3c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-4.3,4.3h5.6c.6,0,1,.4,1,1s-.4,1-1,1h-5.6l4.3,4.3c.4.4.4,1,0,1.4s-1,.4-1.4,0l-4.3-4.3v5.6c0,.6-.4,1-1,1Z" />
  </svg>
);

export const IconCheckHeart = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCheckHeart"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M12,21.7c-.7,0-1.3-.4-1.7-.8-2.3-2-6.3-5.7-7.8-7.6C.1,10.2.6,6,3.5,3.5c2.5-2.1,6-2,8.5.2,2.5-2.2,5.9-2.3,8.5-.2,2.9,2.4,3.4,6.6,1,9.6-1.5,1.9-5.5,5.8-7.8,7.8-.4.4-1,.8-1.7.8ZM7.6,4c-.9,0-1.9.3-2.8,1.1-2.1,1.8-2.4,4.7-.8,6.9,1.5,1.9,5.8,5.8,7.6,7.4.2.2.3.3.4.4,0,0,6.4-5.9,7.9-7.8,1.7-2.2,1.4-5.1-.7-6.9-2.2-1.9-4.9-1.1-6.4.7-.4.4-1.1.4-1.5,0-.9-1.1-2.3-1.8-3.7-1.8ZM11,14c-.3,0-.5,0-.7-.3l-2-2c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l1.3,1.3,3.8-3.8c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-4.5,4.5c-.2.2-.5.3-.7.3Z" />
  </svg>
);

export const IconCheckVerified = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCheckVerified"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M8.7,23c-.4,0-.8,0-1.2-.2-.7-.3-1.3-.9-1.6-1.6l-.7-1.7c-.1-.3-.3-.5-.6-.6l-1.7-.7c-.7-.3-1.3-.9-1.6-1.6-.3-.8-.3-1.6,0-2.3l.7-1.7c.1-.3.1-.5,0-.8l-.7-1.7c-.2-.4-.2-.8-.2-1.2s0-.8.2-1.2c.2-.4.4-.7.7-1,.3-.3.6-.5,1-.7l1.7-.7c.3-.1.5-.3.6-.6l.7-1.7c.3-.7.9-1.3,1.6-1.6.7-.3,1.6-.3,2.3,0l1.7.7c.3.1.5.1.8,0l1.7-.7c.7-.3,1.6-.3,2.3,0,.7.3,1.3.9,1.6,1.6l.7,1.7s0,0,0,0c.1.3.3.5.6.6l1.7.7c.7.3,1.3.9,1.6,1.6.3.7.3,1.6,0,2.3l-.7,1.7c-.1.3-.1.5,0,.8l.7,1.7c.2.4.2.8.2,1.2s0,.8-.2,1.2c-.2.4-.4.7-.7,1-.3.3-.6.5-1,.7l-1.7.7c-.3.1-.5.3-.6.6l-.7,1.7c-.3.7-.9,1.3-1.6,1.6-.7.3-1.6.3-2.3,0l-1.7-.7c-.3-.1-.5-.1-.8,0l-1.7.7c-.4.2-.8.2-1.2.2ZM8.7,3c-.1,0-.3,0-.4,0-.3.1-.5.3-.6.6l-.7,1.7c-.3.7-.9,1.3-1.6,1.6l-1.7.7c-.1,0-.2.1-.3.2s-.2.2-.2.3c0,.1,0,.3,0,.4s0,.3,0,.4l.7,1.7c.3.7.3,1.6,0,2.3l-.7,1.7c-.1.3-.1.5,0,.8.1.2.3.5.6.6l1.7.7c.7.3,1.3.9,1.6,1.6l.7,1.7c.1.3.3.5.6.6.3.1.5.1.8,0l1.7-.7c.7-.3,1.6-.3,2.3,0l1.7.7c.3.1.5.1.8,0,.3-.1.5-.3.6-.6l.7-1.7c.3-.8.9-1.3,1.6-1.6l1.7-.7c.1,0,.2-.1.3-.2,0,0,.2-.2.2-.3,0-.1,0-.3,0-.4s0-.3,0-.4l-.7-1.7c-.3-.7-.3-1.6,0-2.3l.7-1.7c.1-.3.1-.5,0-.8-.1-.3-.3-.5-.6-.6l-1.7-.7c-.7-.3-1.3-.9-1.6-1.6,0,0,0,0,0,0l-.7-1.7c-.1-.3-.3-.5-.6-.6-.3-.1-.5-.1-.8,0l-1.7.7c-.7.3-1.6.3-2.3,0l-1.7-.7c-.1,0-.3,0-.4,0ZM11,15c-.3,0-.5,0-.7-.3l-2-2c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l1.3,1.3,3.8-3.8c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-4.5,4.5c-.2.2-.5.3-.7.3Z" />
  </svg>
);

export const IconCheckmark = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCheckmark"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    role="img"
  >
    <path
      d="M9,18c-0.3,0-0.5-0.1-0.7-0.3l-5-5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L9,15.6L19.3,5.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4
	l-11,11C9.5,17.9,9.3,18,9,18z"
    />
  </svg>
);

export const IconCheckmarkSmall = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCheckmarkSmall"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 14 14"
    role="img"
  >
    <path d="M5.2,10.9h0c-.3,0-.5-.1-.7-.3l-2.9-2.9c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l2.2,2.2,5.7-5.7c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-6.4,6.4c-.2.2-.4.3-.7.3Z" />
  </svg>
);

export const IconChevronRight = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconChevronRight"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 20 20"
    role="img"
  >
    <path d="M7.5,15.8c-.2,0-.4,0-.6-.2-.3-.3-.3-.9,0-1.2l4.4-4.4-4.4-4.4c-.3-.3-.3-.9,0-1.2.3-.3.9-.3,1.2,0l5,5c.3.3.3.9,0,1.2l-5,5c-.2.2-.4.2-.6.2Z" />
  </svg>
);

export const IconClock = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconClock" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M12,23c-6.1,0-11-4.9-11-11S5.9,1,12,1s11,4.9,11,11-4.9,11-11,11ZM12,3C7,3,3,7,3,12s4,9,9,9,9-4,9-9S17,3,12,3ZM16,15c-.2,0-.3,0-.4-.1l-4-2c-.3-.2-.6-.5-.6-.9v-6c0-.6.4-1,1-1s1,.4,1,1v5.4l3.4,1.7c.5.2.7.8.4,1.3-.2.4-.5.6-.9.6Z" />
  </svg>
);

export const IconCornerDownLeft = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCornerDownLeft"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 16 16"
    role="img"
  >
    <path d="M6,13.8c-.1,0-.3,0-.4-.1l-3.3-3.3s0,0,0,0h0s0,0,0-.1c0,0,0-.1,0-.2s0-.1,0-.2c0,0,0-.1.1-.2,0,0,0,0,0,0l3.3-3.3c.2-.2.5-.2.7,0s.2.5,0,.7l-2.5,2.5h3.1c2.2,0,3.2,0,4-.4.7-.3,1.2-.9,1.5-1.5.4-.7.4-1.8.4-4v-.9c0-.3.2-.5.5-.5s.5.2.5.5v.9c0,2.3,0,3.5-.5,4.4-.4.9-1.1,1.5-2,2-1,.5-2.1.5-4.4.5h-3.1l2.5,2.5c.2.2.2.5,0,.7s-.2.1-.4.1Z" />
  </svg>
);

export const IconCross = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCross"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    role="img"
  >
    <g>
      <path d="M15,5L5,15 M5,5l10,10" />
      <path
        d="M15,15.8c-0.2,0-0.4-0.1-0.6-0.2L10,11.2l-4.4,4.4c-0.3,0.3-0.9,0.3-1.2,0c-0.3-0.3-0.3-0.9,0-1.2L8.8,10L4.4,5.6
		c-0.3-0.3-0.3-0.9,0-1.2c0.3-0.3,0.9-0.3,1.2,0L10,8.8l4.4-4.4c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2L11.2,10l4.4,4.4
		c0.3,0.3,0.3,0.9,0,1.2C15.4,15.8,15.2,15.8,15,15.8z"
      />
    </g>
  </svg>
);

export const IconDot = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconDot" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M11.9999 18.6667C15.6818 18.6667 18.6666 15.6819 18.6666 12C18.6666 8.31811 15.6818 5.33334 11.9999 5.33334C8.31802 5.33334 5.33325 8.31811 5.33325 12C5.33325 15.6819 8.31802 18.6667 11.9999 18.6667Z" />
  </svg>
);

export const IconError = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconError" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M12,22.9c-.1,0-.3,0-.4,0-.3,0-.5-.2-.8-.4l-7.4-4.1c-.4-.2-.6-.3-.8-.6-.2-.2-.3-.4-.4-.7-.1-.3-.1-.6-.1-1V7.9c0-.4,0-.7.1-1,0-.3.2-.5.4-.7.2-.2.5-.4.8-.6L10.7,1.6c.3-.2.6-.3.9-.4.3,0,.5,0,.8,0,.3,0,.5.2.9.4l7.4,4.1c.3.2.6.3.8.6.2.2.3.5.4.7,0,.3,0,.6,0,1v8.1c0,.4,0,.7-.1,1,0,.3-.2.5-.4.7-.2.2-.5.4-.8.6l-7.4,4.1c-.3.2-.6.3-.9.4-.1,0-.3,0-.4,0ZM12,3.1s-.1,0-.3.2l-7.4,4.1c-.2,0-.3.1-.3.2,0,0,0,.2,0,.3v8.1c0,.2,0,.3,0,.4,0,0,.1,0,.3.1l7.4,4.1c.2,0,.2.1.3.2,0,0,.1,0,.3-.2l7.4-4.1c.2,0,.3-.1.3-.2,0,0,0-.2,0-.3V7.9c0-.2,0-.3,0-.4,0,0-.1,0-.3-.1l-7.4-4.1c-.1,0-.2-.1-.3-.2ZM12,17c-.6,0-1-.4-1-1s.4-1,1-1h0c.6,0,1,.4,1,1s-.4,1-1,1ZM12,13c-.6,0-1-.4-1-1v-4c0-.6.4-1,1-1s1,.4,1,1v4c0,.6-.4,1-1,1Z" />
  </svg>
);

export const IconHourglass = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconHourglass"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M20,23H4c-.6,0-1-.4-1-1s.4-1,1-1h1v-1.8c0-.9,0-1.4.1-1.9.1-.5.3-.9.6-1.3.3-.4.7-.7,1.4-1.3l3.4-2.8-3.4-2.8c-.7-.6-1.1-.9-1.4-1.3-.3-.4-.5-.8-.6-1.3-.1-.5-.1-1-.1-1.9v-1.8h-1c-.6,0-1-.4-1-1s.4-1,1-1h16c.6,0,1,.4,1,1s-.4,1-1,1h-1v1.8c0,.9,0,1.4-.1,1.9-.1.5-.3.9-.6,1.3-.3.4-.7.7-1.3,1.3l-3.4,2.8,3.4,2.8c.7.6,1.1.9,1.4,1.3.3.4.5.8.6,1.3.1.5.1,1,.1,1.9v1.8h1c.6,0,1,.4,1,1s-.4,1-1,1ZM7,21h10v-1.8c0-.7,0-1.1,0-1.4,0-.2-.2-.4-.3-.6-.2-.2-.4-.5-1-.9l-3.6-3-3.6,3c-.6.5-.9.7-1,.9-.1.2-.2.4-.3.6,0,.3,0,.6,0,1.4v1.8ZM7,3v1.8c0,.7,0,1.1,0,1.4,0,.2.2.4.3.6.2.2.4.5,1,.9l3.6,3,3.6-3c.6-.5.9-.7,1-.9.1-.2.2-.4.3-.6,0-.3,0-.6,0-1.4v-1.8H7Z" />
  </svg>
);

export const IconInfo = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconInfo" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M12,23c-6.1,0-11-4.9-11-11S5.9,1,12,1s11,4.9,11,11-4.9,11-11,11ZM12,3C7,3,3,7,3,12s4,9,9,9,9-4,9-9S17,3,12,3ZM12,17c-.6,0-1-.4-1-1v-4c0-.6.4-1,1-1s1,.4,1,1v4c0,.6-.4,1-1,1ZM12,9c-.6,0-1-.4-1-1s.4-1,1-1h0c.6,0,1,.4,1,1s-.4,1-1,1Z" />
  </svg>
);

export const IconLock = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconLock" {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img">
    <path d="M11.5 2.043c-2.758.216-5.014 2.334-5.42 5.089-.025.171-.058.7-.073 1.176l-.027.864-.257.07c-1.156.316-2.245 1.457-2.52 2.638-.169.727-.181.954-.181 3.62 0 2.666.012 2.893.181 3.62.289 1.245 1.429 2.384 2.68 2.677.778.183.79.183 6.117.183s5.339 0 6.117-.183c.72-.169 1.484-.665 1.983-1.289.459-.573.693-1.158.825-2.068.071-.481.071-5.401.001-5.88-.134-.908-.365-1.489-.825-2.07-.449-.567-1.191-1.075-1.824-1.248l-.257-.07-.027-.864a15.432 15.432 0 0 0-.073-1.176 5.965 5.965 0 0 0-1.746-3.416c-1.26-1.221-2.91-1.812-4.674-1.673m1.56 2.094c1.396.41 2.385 1.399 2.804 2.803.098.328.109.435.126 1.21l.019.85H7.991l.019-.85c.017-.775.028-.882.126-1.21.204-.683.493-1.196.944-1.675a3.955 3.955 0 0 1 1.716-1.083c.501-.156.688-.179 1.324-.164.483.011.64.031.94.119m4.517 6.955c.342.102.586.247.835.496.257.256.4.503.497.856.065.233.071.503.071 3.056s-.006 2.823-.071 3.056c-.097.353-.24.6-.497.856a1.78 1.78 0 0 1-.856.497c-.239.066-.613.071-5.556.071s-5.317-.005-5.556-.071a1.78 1.78 0 0 1-.856-.497 1.78 1.78 0 0 1-.497-.856c-.065-.233-.071-.503-.071-3.056s.006-2.823.071-3.056c.097-.352.242-.603.49-.849.346-.342.645-.486 1.145-.553.14-.018 2.585-.03 5.434-.027 4.819.006 5.196.012 5.417.077m-5.999 2.523c-.216.1-.391.28-.494.505-.054.119-.064.329-.064 1.38 0 1.354.003 1.371.242 1.656.357.422 1.119.422 1.476 0 .239-.285.242-.302.242-1.656 0-1.051-.01-1.261-.064-1.38a1 1 0 0 0-1.338-.505" />
  </svg>
);

export const IconMinus = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconMinus" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 14 14" role="img">
    <path d="M2.730 6.023 C 2.454 6.069,2.147 6.301,2.023 6.559 C 1.898 6.817,1.891 7.154,2.005 7.408 C 2.071 7.557,2.224 7.732,2.378 7.835 C 2.650 8.017,2.334 8.006,7.082 7.998 L 11.305 7.992 11.437 7.938 C 11.696 7.834,11.920 7.609,12.019 7.356 C 12.092 7.167,12.093 6.834,12.020 6.646 C 11.951 6.470,11.831 6.318,11.662 6.193 C 11.387 5.991,11.817 6.009,7.070 6.005 C 4.629 6.003,2.804 6.011,2.730 6.023 " />
  </svg>
);

export const IconPencil = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconPencil"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M5.84,19.65c-.26,0-.52-.1-.71-.29-.21-.21-.32-.52-.29-.82l.25-2.26c.03-.31.06-.51.13-.74.07-.2.16-.39.27-.57.13-.2.28-.35.5-.56l6.79-6.79s0,0,.01-.01c0,0,0,0,.01-.01l1.99-1.99c1.12-1.12,2.96-1.13,4.08,0s1.12,2.96,0,4.08l-8.8,8.8c-.22.22-.36.36-.56.5-.18.12-.37.21-.57.27-.22.07-.42.09-.71.13l-2.28.25s-.07,0-.11,0ZM13.51,9.73l-6.1,6.1c-.12.12-.19.18-.24.25-.06.16-.06.25-.08.42l-.11,1.02,1.01-.11c.18-.02.27-.02.34-.04.15-.08.21-.15.33-.28l6.1-6.1-1.25-1.25ZM14.92,8.31l1.25,1.25,1.29-1.29c.34-.35.34-.91,0-1.25s-.91-.35-1.25,0l-1.29,1.29Z" />
  </svg>
);

export const IconPlus = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconPlus" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 20" role="img">
    <path d="M9.768 3.37a.905.905 0 0 0-.564.58c-.026.092-.037.915-.037 2.674v2.541l-2.609.009c-2.914.01-2.715-.006-2.969.248a.716.716 0 0 0-.237.578c0 .252.062.403.237.578.254.254.055.238 2.969.248l2.607.009.009 2.607c.01 2.914-.006 2.715.248 2.969a.716.716 0 0 0 .578.237.716.716 0 0 0 .578-.237c.254-.254.238-.055.248-2.969l.009-2.607 2.607-.009c2.914-.01 2.715.006 2.969-.248a.716.716 0 0 0 .237-.578.716.716 0 0 0-.237-.578c-.254-.254-.055-.238-2.969-.248l-2.607-.009-.009-2.607c-.009-2.443-.013-2.616-.07-2.73a1.046 1.046 0 0 0-.412-.412.997.997 0 0 0-.576-.046" />
  </svg>
);

export const IconThumbsUp = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconThumbsUp"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M17.4,23H4c-1.7,0-3-1.3-3-3v-7c0-1.7,1.3-3,3-3h2.4l3.5-7.9c.3-.7.9-1.1,1.7-1.1,1.9,0,3.5,1.6,3.5,3.5v3.5h3.5c1.2,0,2.3.5,3,1.4s1.1,2.1.9,3.2l-1.1,7c-.3,2-2,3.4-4,3.4ZM8,21h9.4c1,0,1.8-.7,2-1.7l1.1-7c0-.6,0-1.2-.5-1.6-.4-.4-.9-.7-1.5-.7h-3.5c-1.1,0-2-.9-2-2v-3.5c0-.8-.6-1.4-1.4-1.5l-3.5,7.8c0,.1-.1.2-.2.3v9.9ZM4,12c-.6,0-1,.4-1,1v7c0,.6.4,1,1,1h2v-9h-2Z" />
  </svg>
);

export const IconTrash = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconTrash" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M7,5C7.007,3.768 7.075,3.133 7.327,2.638C7.615,2.074 8.074,1.615 8.638,1.327C9.159,1.061 9.835,1 11.2,1L12.8,1C14.165,1 14.841,1.061 15.362,1.327C15.926,1.615 16.385,2.074 16.673,2.638C16.925,3.133 16.993,3.768 17,5L21,5C21.552,5 22,5.448 22,6C22,6.552 21.552,7 21,7L20,7L20,17.2C20,19.125 19.939,20.081 19.564,20.816C19.181,21.569 18.569,22.181 17.816,22.564C17.081,22.939 16.125,23 14.2,23L9.8,23C7.875,23 6.919,22.939 6.184,22.564C5.431,22.181 4.819,21.569 4.436,20.816C4.061,20.081 4,19.125 4,17.2L4,7L3,7C2.448,7 2,6.552 2,6C2,5.448 2.448,5 3,5L7,5ZM18,7L6,7L6,17.2C6,18.636 5.939,19.359 6.218,19.908C6.41,20.284 6.716,20.59 7.092,20.782C7.64,21.061 8.364,21 9.8,21L14.2,21C15.636,21 16.36,21.061 16.908,20.782C17.284,20.59 17.59,20.284 17.782,19.908C18.061,19.36 18,18.636 18,17.2L18,7ZM8.999,5L15.001,5C15.008,4.254 15.048,3.854 14.891,3.546C14.795,3.358 14.642,3.205 14.454,3.109C14.119,2.938 13.676,3 12.8,3L11.2,3C10.324,3 9.881,2.938 9.546,3.109C9.358,3.205 9.205,3.358 9.109,3.546C8.952,3.854 8.992,4.254 8.999,5ZM13,11.5C13,10.948 13.448,10.5 14,10.5C14.552,10.5 15,10.948 15,11.5L15,16.5C15,17.052 14.552,17.5 14,17.5C13.448,17.5 13,17.052 13,16.5L13,11.5ZM9,11.5C9,10.948 9.448,10.5 10,10.5C10.552,10.5 11,10.948 11,11.5L11,16.5C11,17.052 10.552,17.5 10,17.5C9.448,17.5 9,17.052 9,16.5L9,11.5Z" />
  </svg>
);

export const IconUser = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconUser" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M20,22c-.6,0-1-.4-1-1,0-1.3,0-1.9-.1-2.4-.3-1-1-1.7-2-2-.4-.1-1.1-.1-2.4-.1h-5c-1.3,0-1.9,0-2.4.1-1,.3-1.7,1-2,2-.1.4-.1,1.1-.1,2.4s-.4,1-1,1-1-.4-1-1c0-1.5,0-2.2.2-3,.5-1.6,1.7-2.8,3.3-3.3.7-.2,1.5-.2,3-.2h5c1.5,0,2.2,0,3,.2,1.6.5,2.8,1.7,3.3,3.3.2.7.2,1.5.2,3s-.4,1-1,1ZM12,13c-3,0-5.5-2.5-5.5-5.5s2.5-5.5,5.5-5.5,5.5,2.5,5.5,5.5-2.5,5.5-5.5,5.5ZM12,4c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5,3.5-1.6,3.5-3.5-1.6-3.5-3.5-3.5Z" />
  </svg>
);

export const IconWarning = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconWarning"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    role="img"
  >
    <path
      d="M20.2,21.5H3.8c-1.1,0-1.7,0-2.2-0.4c-0.5-0.3-0.8-0.8-0.8-1.4c-0.1-0.6,0.2-1.2,0.8-2.1L9.8,3.4l0,0l0.9,0.5L9.8,3.4
	c0.5-0.9,0.8-1.5,1.4-1.7c0.5-0.2,1.1-0.2,1.6,0c0.6,0.3,0.9,0.8,1.4,1.7l8.2,14.2c0.5,0.9,0.8,1.5,0.8,2.1
	c-0.1,0.6-0.4,1.1-0.8,1.4C21.9,21.5,21.3,21.5,20.2,21.5z M2.8,19.5c0.2,0,0.6,0,1,0h16.4c0.4,0,0.8,0,1,0
	c-0.1-0.2-0.3-0.5-0.5-0.9L12.5,4.4c-0.2-0.3-0.4-0.7-0.5-0.9c-0.1,0.2-0.3,0.5-0.5,0.9l-0.9-0.5l0.9,0.5L3.3,18.6
	C3.1,18.9,2.9,19.3,2.8,19.5z M12,18c-0.6,0-1-0.4-1-1s0.4-1,1-1h0c0.6,0,1,0.4,1,1S12.6,18,12,18z M12,14c-0.6,0-1-0.4-1-1V9
	c0-0.6,0.4-1,1-1s1,0.4,1,1v4C13,13.6,12.6,14,12,14z"
    />
  </svg>
);

export const IconWarningSquare = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconWarningSquare"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M22,0H2C.9,0,0,.9,0,2v20c0,1.1.9,2,2,2h20c1.1,0,2-.9,2-2V2c0-1.1-.9-2-2-2ZM10.7,6.5h2.7v2.9l-.3,4.6h-2l-.3-4.6v-2.9ZM13.4,17.5h-2.8v-2.7h2.8v2.7Z" />
  </svg>
);
